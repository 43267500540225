import React from "react";
import { useOutletContext } from "react-router-dom";

const IndividualBlog = () => {
  const { currentdata } = useOutletContext();

  function urltoanchor(data, url, urlIndex) {
    return data?.split(/(<url>.*?<\/url>)/g).map((part, index) => {
      if (part.startsWith("<url>") && part.endsWith("</url>")) {
        const linkText = part.replace(/<\/?url>/g, "");
        const linkUrl = url[urlIndex];
        urlIndex++;
        return (
          <a
            key={index}
            href={linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-14 md:text-16 text-blue-500 underline"
          >
            {linkText}
          </a>
        );
      }
      return part;
    });
  }

  return (
    <div className="2xl:container flex justify-center items-center px-6 md:px-[70px] md:py-[96px] py-[48px] w-full blogasset relative">
      <div className="flex flex-col text-left w-full gap-12 md:gap-[72px] lg:gap-[96px]">
        <div className="flex flex-col gap-6 md:gap-8 lg:gap-10 md:w-[60%]">
          <div className="flex flex-col gap-2 md:gap-4 lg:gap-6">
            <h1 className="text-20 md:text-32 lg:text-48 text-headingtext md:text-32 text-20 font-[800] font-avenir">
              {`“${currentdata?.title}”`}
            </h1>
            <p className="text-14 md:text-16 text-paratext">
              {currentdata?.description}
            </p>
          </div>
          <div className="text-14 font-[500] md:text-16 flex gap-8">
            <p className=" text-headingtext font-[500]">
              Blog Post: {currentdata?.blog_words}
            </p>
            <p>{currentdata?.date}</p>
          </div>
        </div>
        <div className="w-full border border-[#CED0D6] p-4 md:p-6 lg:p-8 rounded-[16px]">
          <div className="w-full md:w-[40%] md:ml-auto md:float-right md:ml-8 mb-6 md:mb-8">
            <img
              src={process.env.PUBLIC_URL + currentdata.image}
              alt="blog illustration"
              className="w-full"
            />
          </div>
          <div className="">
            {currentdata?.para?.map((data, i) => {
              let urlIndex = 0;
              return Object.keys(currentdata?.para[i]).includes("list") &&
                Object.keys(currentdata?.para[i]).includes("para") ? (
                <div className="flex flex-col md:flex-row bg-[#F0F4FC] p-3 md:p-6 gap-3 md:gap-6 w-full mb-8">
                  <div className="md:w-[40%] md:p-6 lg:p-8 bg-white flex flex-col p-3 gap-2 md:gap-4 lg:gap-6">
                    <h className="text-16 md:text-18 lg:text-20 text-headingtext font-[600]">
                      {data?.title}
                    </h>
                    <p className="text-14 md:text-16 text-paratext">
                      {data?.para}
                    </p>
                  </div>
                  <div className="md:w-[60%] flex flex-col gap-4 p-3 md:p-6 lg:p-8 bg-white">
                    <p className=" text-14 md:text-16 text-paratext font-[500]">
                      {data.list.title}
                    </p>
                    <ul className="flex flex-col gap-3 list-disc">
                      {data?.list?.list?.map((item, i) => (
                        <li className="text-14 md:text-16 text-paratext ml-3 md:ml-5">
                          {item?.value1.includes("<url>")
                            ? urltoanchor(item?.value1, item?.url, urlIndex)
                            : item?.value1}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : Object.keys(currentdata?.para[i]).includes("list") ? (
                <>
                  <div className="flex flex-col mb-6 gap-2 md:gap-4">
                    <h className="text-16 md:text-18 lg:text-20 text-headingtext font-[600]">
                      {data?.title}
                    </h>
                    <p className="text-14 md:text-16 text-paratext font-[500]">
                      {data.list.title}
                    </p>
                    <ul className="flex flex-col gap-3 list-disc">
                      {data?.list?.list?.map((item, i) => (
                        <li
                          key={i}
                          className="text-14 md:text-16 text-paratext ml-3 md:ml-5"
                        >
                          {item?.value1.includes("<url>")
                            ? urltoanchor(item?.value1, item?.url, urlIndex)
                            : item?.value1}
                        </li>
                      ))}
                    </ul>
                  </div>
                  {data?.list?.para ? (
                    <div>
                      <p className="text-14 md:text-16 text-paratext mb-8">
                        {data?.list.para.includes("<url>")
                          ? urltoanchor(
                              data?.list?.para,
                              data?.list?.url,
                              urlIndex
                            )
                          : data?.list.para}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {data?.list?.list1 ? (
                    <ul className="flex flex-col gap-3 list-disc mb-8">
                      {data?.list?.list1?.map((item, i) => (
                        <li className="text-14 md:text-16 text-paratext ml-3 md:ml-5">
                          {item?.value1.includes("<url>")
                            ? urltoanchor(item?.value1, item?.url, urlIndex)
                            : item?.value1}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <div className="flex flex-col gap-3 md:gap-6 mb-8">
                  <h className="text-16 md:text-18 lg:text-20 text-headingtext font-[600]">
                    {data.title}
                  </h>
                  <p className="text-14 md:text-16 text-paratext">
                    {data?.para.includes("<url>")
                      ? urltoanchor(data?.para, data?.url, urlIndex)
                      : data.para}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualBlog;
