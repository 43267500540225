import React from "react";
import SectionHeader from "../../components/SectionHeader";

const Ourcommitment = () => {
  const data = [
    {
      heading: "Community First",
      desc: "We build bridges between caregivers, transforming individual journeys into collective strength through shared experiences and understanding.",
      bg: "#EDF1FF",
      stroke: "#6785FF",
    },
    {
      heading: "Compassion Without Judgment",
      desc: "We're dedicated to building a world that celebrates neurodiversity, transforming how society understands and supports neurodiversity families.",
      bg: "#E4F5F2",
      stroke: "#4DC7B8",
    },
    {
      heading: "Excellence in Support",
      desc: "Every resource, tool, and piece of guidance is thoughtfully verified and curated. We continuously evolve to ensure you have access to the most reliable support when you need it.",
      bg: "#E6FAE7",
      stroke: "#30EE36",
    },
    {
      heading: "Connection with Purpose",
      desc: "We believe in the power of relationships that grow from shared experiences into lifelong support systems, creating a network that feels like family.",
      bg: "#FCF3E6",
      stroke: "#FFB84D",
    },
    {
      heading: "Whole Family Approach",
      desc: "Your family's journey is unique. Our platform is shaped by and for caregivers, ensuring every feature serves both practical needs and emotional well-being—because when caregivers thrive, families flourish.",
      bg: "#FAEBE6",
      stroke: "#EE5A30",
    },
  ];

  return (
    <div className="2xl:container px-6 md:px-[70px]">
      <div className="flex flex-col gap-8 md:gap-10 lg:gap-16">
        <SectionHeader
          title={"OUR COMMITMENT"}
          description={"Shaping Tomorrow, Together"}
          para={
            "Our promise extends beyond building a platform—we're creating lasting change for neurodiversity families through meaningful action and unwavering support."
          }
          textalign={"center"}
          justify={"center"}
        />
        <div className="grid grid-cols-1 md:grid-cols-6 gap-4 md:gap-6">
          {data.map((item, i) => (
            <div
              key={i}
              className={`${
                i === 1 ? "md:col-span-4" : "md:col-span-2"
              } col-span-1 lg:pattern text-left p-6 md:p-8 gap-2 md:gap-6 flex flex-col rounded-[24px] border-[1px]`}
              style={{ background: item.bg, borderColor: item.stroke }}
            >
              <h1 className="text-headingtext text-16 md:text-24 font-[600]">
                {item.heading}
              </h1>
              <p className="text-paratext text-14 md:text-16">{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Ourcommitment;
