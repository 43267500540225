import React from "react";
import Contactus1 from "../components/Contactus/Contactus1";
import Joinvillage from "../components/landingpage/Joinvillage";

const Contactus = () => {
  return (
    <div className="w-full flex flex-col items-center gap-[56px] md:gap-[120px]">
      <Contactus1 />
      <Joinvillage />
    </div>
  );
};

export default Contactus;
