import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { sentDetails } from "../api/api";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "Thanks for showing Interest!",
    email: "",
    phone: "",
    service: "",
    message: "The client is interested in getting updates",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const date = new Date();

  let year = date.getFullYear();

  const footerdata = [
    {
      title: "Quick Links",
      subtitles: [
        { name: "Our Story", url: "/ourstory" },
        { name: "Our App", url: "/ourapp" },
        { name: "Events", url: "/events" },
        { name: "Become an Ally", url: "/becomeanally" },
        { name: "Resources", url: "/resources" },
      ],
    },
    {
      title: "Company",
      subtitles: [
        {
          name: "Blog",
          url: "/blog",
        },
        {
          name: "News/Update",
          url: "/news&updates",
        },
        {
          name: "Contact Us",
          url: "/contactus",
        },
      ],
    },
  ];

  const sociallogos = [
    {
      name: "facebook",
      imgpath: "/images/header/Facebook.svg",
      url: "https://www.facebook.com/share/17eZ7J3oY9/?mibextid=qi2Omg",
    },
    {
      name: "Instagram",
      imgpath: "/images/header/Instagram.svg",
      url: "https://www.instagram.com/socialspectrum2024?igsh=MW1jaWhzZnNlcHVzbw==",
    },
    {
      name: "LinkedIn",
      imgpath: "/images/header/LinkedIn.svg",
      url: "https://www.linkedin.com/company/socialspectrum/",
    },
    {
      name: "X",
      imgpath: "/images/header/X.svg",
      url: "https://x.com/SocialSpectrum4?s=08",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    toast.success("Your details is being submitted");
    // Create a promise to handle the response manually

    try {
      const response = await sentDetails(formData);
      // console.log(response);

      if (response.data) {
        setFormData({
          email: "",
        });
        toast.success(response.data.message || "Message sent successfully!");
      } else if (response.error) {
        toast(
          response.error.message + ", Message could not be sent" ||
            "Failed to send message"
        );
      }
    } catch (err) {
      toast("An unexpected error occurred");
    }

    // Use toast.promise to handle loading, success, and error states
    // toast.promise(submissionPromise, {
    //   loading: "Sending message...",
    //   success: (message) => message,
    //   error: (message) => message,
    // });
  };

  // console.log(formData);

  return (
    <div className="2xl:container flex flex-col w-full justify-center items-center bg-[#F7F8FC] mt-[56px] md:mt-[120px]">
      <div className="flex flex-col w-full p-4 md:px-20 md:py-10 gap-16">
        <div className=" flex flex-col md:flex-row w-full justify-between gap-10 md:gap-0 ">
          <div className="flex flex-col gap-10">
            <div>
              <img
                className="h-[32px] sm:h-[40px] md:h-[48px] w-auto"
                src={process.env.PUBLIC_URL + "/images/sslogo.svg"}
                alt="spectrumlogo"
              />
            </div>
            <div className="flex flex-col w-full md:w-8/12 gap-10">
              <h1 className="text-[#060D24] font-bold text-18 text-left">
                To receive updates from the Social Spectrum team, leave your
                email here.
              </h1>
              <div className="flex border-b-[1px] border-[#C1C7DB] justify-between">
                <form
                  onSubmit={handleSubmit}
                  method="post"
                  className="input_details w-full"
                >
                  <div className="md:grid gap-4">
                    <div className="w-full flex flex-col gap-3 md:gap-5 text-E1E7FA hidden">
                      <label className="text-E1E7FA text-left">
                        First Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        placeholder="First Name"
                        className="inputfield"
                        // required
                      />
                    </div>
                    <div className="w-full flex flex-col gap-3 md:gap-5 text-E1E7FA mt-4 md:mt-0 hidden">
                      <label className="text-E1E7FA text-left">Last Name</label>
                      <input
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        className="inputfield"
                      />
                    </div>
                    {/* <div className="w-full flex flex-col gap-3 md:gap-5 mt-4 md:mt-0 text-E1E7FA md:mt-6 hidden">
                    <label className="text-E1E7FA text-left">E-mail</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      className="inputfield"
                      required
                    />
                  </div> */}
                    <div className="flex justify-between w-full">
                      <input
                        type="email"
                        placeholder="Enter your email"
                        required
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="outline-none py-2 w-full bg-[#F7F8FC]"
                      />
                      <button type="submit">
                        <img
                          src={process.env.PUBLIC_URL + "/images/sendarrow.png"}
                          alt="submit"
                        />
                      </button>
                    </div>
                    <div className="w-full flex flex-col gap-3 md:gap-5 mt-4 md:mt-0 text-E1E7FA md:mt-6 hidden">
                      <label className="text-E1E7FA text-left">
                        Phone Number
                      </label>
                      <input
                        type="number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Phone Number"
                        className="inputfield"
                        // required
                      />
                    </div>
                    <div className="w-full flex flex-col gap-3 md:gap-5 mt-4 md:mt-0 col-span-2 text-E1E7FA md:mt-6 hidden">
                      <label className="text-E1E7FA text-left">Message</label>
                      <textarea
                        type="text"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Write your message"
                        className="inputfield min-h-[134px]"
                        // required
                      ></textarea>
                    </div>
                    <div className="hidden" id="success">
                      <h3 className="">Thank you for contacting us!</h3>
                    </div>
                  </div>
                  {/* <div className="w-full mt-8 flex justify-left ">
                  <input
                    type="submit"
                    value="Send Message"
                    className="bg-[#1D43B6] px-6 py-4 rounded-[12px] text-white font-bold cursor-pointer"
                  />
                </div> */}
                </form>
                {/* <input
                type="email"
                value={mail.mail}
                placeholder="Enter your email"
                required
                name="email"
                className="outline-none py-2"
                onChange={handleChange}
              />
              <button>
                <img
                  src={process.env.PUBLIC_URL + "/images/sendarrow.png"}
                  alt="submit"
                />
              </button> */}
              </div>
            </div>
            {/* <div className="flex flex-col md:flex-row text-left gap-8">
              <div className="flex flex-col">
                <p className="text-[#43414D]">E-mail</p>
                <p className="text-[#060D24] font-semibold">
                  admin@socialspectrum.org
                </p>
              </div>
              <div className="flex flex-col">
                <p className="text-[#43414D]">Phone Number</p>
                <p className="text-[#060D24] font-semibold">
                  +1 (201) 895-3801
                </p>
              </div>
            </div> */}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-0 gap-5">
            {footerdata.map((items, i) => (
              <div key={i} className="flex flex-col items-start gap-[18px]">
                <h1 className="text-[#0D121F] text-16 font-[500]">
                  {items.title}
                </h1>
                {items.subtitles.map((titles, i) => (
                  <Link to={titles.url} className="text-[#4B4E56]">
                    {titles.name}
                  </Link>
                ))}
              </div>
            ))}
            <div className="flex flex-col items-start gap-[18px]">
              <div className="flex flex-col gap-[18px] items-start">
                <h1 className="text-16 font-[500] text-[#0D121F]">Email</h1>
                <p className="text-[#4B4E56]">
                  <a href="mailto:admin@socialspectrum.org">
                    admin@socialspectrum.org
                  </a>
                </p>
                <p className="text-[#4B4E56]">
                  <a href="mailto:archana.akhaury@socialspectrum.org">
                    {" "}
                    archana.akhaury@socialspectrum.org 
                  </a>
                </p>
              </div>
              <div className="flex flex-col items-start gap-[18px]">
                <h1 className="text-16 font-[500] text-[#0D121F]">
                  Follow us on
                </h1>
                <div className="flex lg:flex items-center gap-4 text-14 md:text-16 font-bold text-3E3C3F">
                  {sociallogos.map((items, i) => (
                    <Link to={items.url} key={i} target="_">
                      <img
                        src={process.env.PUBLIC_URL + items.imgpath}
                        alt={items.name}
                      />
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="border-[1px] border-[#C1C7DB]"></hr>
        <div className="text-14 md:text-16 flex flex-col-reverse md:flex-row justify-between items-center gap-10 md:gap-0">
          <div className="flex flex-col md:flex-row gap-8">
            <div className="text-paratext">{`© ${year} Social Spectrum`}</div>
            <div className="flex gap-3">
              <Link
                to={"privacy"}
                className="text-[#0D121F] font-semibold underline"
              >
                {" "}
                Privacy Policy
              </Link>{" "}
              <hr className="h-auto border-[1px] border-[#C1C7DB]"></hr>
              <Link
                to={"terms_and_conditions"}
                className="text-[#0D121F] underline font-semibold"
              >
                Terms & Conditions
              </Link>
            </div>
          </div>
          <div className="flex gap-3">
            <Link
              to={
                "https://play.google.com/store/apps/details?id=com.social_spectrum.mobile"
              }
              target="_"
              className="px-4 py-2 rounded-[40px] border-[1px] border-[#C1C7DB]"
            >
              Play Store
            </Link>
            <Link
              to={"https://apps.apple.com/us/app/social-spectrum/id6473112455"}
              target="_"
              className="px-4 py-2 rounded-[40px] border-[1px] border-[#C1C7DB]"
            >
              App Store
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
