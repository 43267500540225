import React from "react";

const Mission = () => {
  return (
    <div className="2xl:container w-full flex flex-col items-center p-2 md:pt-0 md:px-10 lg:px-[70px] md:py-[35px] lg:py-[70px] missionbg bg-cover">
      <div className="flex flex-col md:flex-row md:items-center md:gap-[120px] w-full mt-8 md:mt-16 gap-10 md:gap-0 p-4 md:p-0">
        <div className="w-full md:w-6/12 flex flex-col-reverse text-left gap-8 md:gap-12">
          <div className="flex flex-col gap-4 md:gap-6">
            <h1 className="text-headingtext text-18 md:text-36 font-semibold">
              Vision
            </h1>
            <p className="text-14 md:text-16 text-paratext">
              To create a world where families navigating neurodivergence feel
              seen, supported, and celebrated for their unique
              journeys—fostering inclusive, empowered communities built on
              understanding, connection, and shared growth.
            </p>
          </div>
          <div className="flex flex-col gap-4 md:gap-6">
            <h1 className="text-headingtext text-18 md:text-36 font-semibold">
              Mission
            </h1>
            <p className="text-14 md:text-16 text-paratext">
              To empower parents and caregivers navigating neurodivergence with
              an innovative platform that combines expert insights, cutting-edge
              research, and compassionate, nonjudgmental community
              support—transforming isolation into connection, uncertainty into
              confidence, and individual challenges into collective resilience.
            </p>
          </div>
        </div>
        <div className="w-full md:w-6/12">
          <div className="w-full h-full flex justify-center items-center">
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/ourstoryhero/mission&vision.webp"
              }
              alt="missionimg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
