import React from "react";
import SectionHeader from "../SectionHeader";
import { Link } from "react-router-dom";

const Ourcommitment = () => {
  return (
    <div className="w-full flex justify-center p-4 md:pt-0 sm:px-10 md:px-10 lg:px-20">
      <div className="2xl:container flex flex-col items-center">
        <SectionHeader
          title={"OUR FEATURES"}
          description={"Empowering Caregivers, One Connection at a Time."}
        />
        <div className="grid grid-cols-1 md:grid-cols-3 w-full md:w-11/12 lg:w-full mt-8 md:mt-[58px] gap-6 min-w-[100px]">
          <div className="flex flex-col md:w-full items-start p-6 lg:p-8 rounded-[8px] bg-[#E4F5F2]">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/commitment/commit1.svg"}
                alt="icon1"
              ></img>
            </div>
            <h1 className="mt-6 md:mt-8 text-16 md:text-24 text-headingtext font-semibold font-avenir font-[600]">
              Match & Connect
            </h1>
            <p className="mt-3 md:mt-6 text-14 md:text-16 text-paratext text-left">
              Connect with families who truly understand your journey. Our
              easy-to-use filters help you search by age, gender, interests, and
              unique needs—empowering you to build meaningful connections that
              matter.
            </p>
          </div>
          <div className="flex flex-col  md:w-full items-start p-6 lg:p-8 rounded-[8px] bg-[#FCF3E6]">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/commitment/commit2.svg"}
                alt="icon2"
              ></img>
            </div>
            <h1 className="mt-6 md:mt-8 text-16 font-[600] md:text-24 text-headingtext font-semibold font-avenir">
              Support
            </h1>
            <p className="mt-3 md:mt-6 text-14 md:text-16 text-paratext text-left">
              Social Spectrum is not just a platform, it's a supportive
              community. We provide resources, guidelines, and a network of
              parents who understand the journey you're on.
            </p>
          </div>
          <div className="flex flex-col md:w-full items-start p-6 md:p-8 rounded-[8px] bg-[#FAEBE6]">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/commitment/commit3.svg"}
                alt="icon3"
              ></img>
            </div>
            <h1 className="mt-6 md:mt-8 text-16 font-[600] md:text-24 text-headingtext font-semibold font-avenir">
              Empowerment
            </h1>
            <p className="mt-3 md:mt-6 text-14 md:text-16 text-paratext text-start">
              Our goal is to empower parents to actively contribute to their
              child's social development by facilitating connections that go
              beyond the virtual world
            </p>
          </div>
        </div>
        <div className="hidden md:block md:w-6/12 mt-12">
          <Link
            to={"/ourapp"}
            className="hidden md:flex m-auto px-[25px] py-[15px] border-[1px] border-[#99ABDA] w-fit h-fit text-[16px] text-[#002DA3] font-[600] rounded-[32px]"
          >
            Know More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Ourcommitment;
