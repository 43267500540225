import React from "react";
import Herosection from "../components/ourstory/Herosection";
import Findourvillage from "../components/ourstory/Findourvillage";
import Mission from "../components/landingpage/Mission";
import Ourcommitment from "../components/ourstory/Ourcommitment";
import Joinvillage from "../components/landingpage/Joinvillage";

const Ourstory = () => {
  return (
    <div className="w-full flex flex-col items-center gap-14 md:gap-[120px]">
      <Herosection />
      <Findourvillage />
      <Mission />
      <Ourcommitment />
      <Joinvillage />
    </div>
  );
};

export default Ourstory;
