import React from "react";
import { Link } from "react-router-dom";

const Herosection = () => {
  return (
    <div
      className="2xl:container w-full px-6 md:px-[70px] flex justify-center items-center bg-ourappmobilebg md:bg-ourappbg h-[420px]"
      style={{ backgroundSize: "100% 100%" }}
    >
      <div className="w-full h-full justify-start md:justify-center mt-[65px] md:mt-0 flex flex-col items-center md:items-start gap-6">
        <h className="text-headingtext text-28 md:text-48 font-[800] md:text-left">
          Your Support System in One Place
        </h>
        <p className="text-paratext text-14 md:text-18 ">
          Connect, share, and grow with families who understand your journey.
        </p>
        <div className="mt-3">
          <Link
            to={"/contactus"}
            className="py-2 px-6 md:px-[25px] md:py-[16px] bg-[#002DA3] text-14 text-white font-[600] rounded-[32px]"
          >
            Get Started
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Herosection;
