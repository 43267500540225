import axios from "axios";

async function resolve(promise) {
  let resolved = {
    data: null,
    error: null,
  };
  try {
    resolved.data = await promise;
  } catch (e) {
    resolved.error = e.response ? e.response.data : e;
  }
  return resolved;
}

export async function sentDetails(data) {
  const form = {
    first_name: data.name,
    last_name: `Age: ${data.service}`,
    phone: data.phone,
    message: data.message,
    email: data.email,
  };
  return await resolve(
    axios
      .post(`https://socialspectrum.org/socialspectrum/api/v1/contact`, form, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data)
  );
}
