import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Header = ({ setMobileMenuOpen }) => {
  const { pathname } = useLocation();
  const [menuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setMobileMenuOpen(menuOpen);
  }, [menuOpen]);

  const menuitems = [
    { name: "Home", url: "/" },
    { name: "Our Story", url: "/ourstory" },
    { name: "Our App", url: "/ourapp" },
    { name: "Events", url: "/events" },
    { name: "Become an Ally", url: "/becomeanally" },
    { name: "Resources", url: "/resources" },
    { name: "Blog", url: "/blog" },
  ];

  const sociallogos = [
    {
      name: "facebook",
      imgpath: "/images/header/Facebook.svg",
      url: "https://www.facebook.com/share/17eZ7J3oY9/?mibextid=qi2Omg",
    },
    {
      name: "Instagram",
      imgpath: "/images/header/Instagram.svg",
      url: "https://www.instagram.com/socialspectrum2024?igsh=MW1jaWhzZnNlcHVzbw==",
    },
    {
      name: "LinkedIn",
      imgpath: "/images/header/LinkedIn.svg",
      url: "https://www.linkedin.com/company/socialspectrum/",
    },
    {
      name: "X",
      imgpath: "/images/header/X.svg",
      url: "https://x.com/SocialSpectrum4?s=08",
    },
  ];

  const handleclick = () => {
    setIsMenuOpen(!menuOpen);
  };

  const closemenu = () => {
    setIsMenuOpen(false);
  };

  const mobref = useRef();

  useEffect(() => {
    const closemenu = (e) => {
      if (
        mobref.current &&
        !mobref.current.contains(e.target) &&
        !e.target.closest(".menubarbtn")
      ) {
        setIsMenuOpen(false);
      }
    };
    document.body.addEventListener("click", closemenu);
    return () => document.body.removeEventListener("click", closemenu);
  });

  return (
    <div className=" 2xl:container w-full flex justify-center bg-white sticky top-0 z-50 items-center">
      <div
        className="p-4 md:px-20 w-full flex items-center h-[56px] md:h-[88px] justify-between bg-white border-b md:border-b-0"
        style={{
          boxShadow:
            "0px 51px 20px rgba(161, 161, 165, 0.01), 0px 29px 17px rgba(161, 161, 165, 0.05), 0px 13px 13px rgba(161, 161, 165, 0.09), 0px 3px 7px rgba(161, 161, 165, 0.1)",
        }}
      >
        <div className="w-[6/12] md:w-full justify-between flex gap-20 items-center">
          <Link to={"/"} className="flex items-center justify-left">
            <img
              className="h-[32px] sm:h-[40px] md:h-[48px] w-auto min-w-[150px]"
              src={process.env.PUBLIC_URL + "/images/sslogo.svg"}
              alt="spectrum_logo"
            />
          </Link>
          {/* <Link to={"/"} className="flex items-center block md:hidden">
            <img
              className="h-auto w-auto"
              src={process.env.PUBLIC_URL + "/images/spectrumlogo.svg"}
              alt="spectrum_logo"
            />
          </Link> */}
          <div className="hidden lg:flex flex-row gap-4">
            {menuitems.map((items, i) => (
              <Link to={items.url} key={i}>
                <p
                  className={`hover:text-[#002DA3] hover:font-[500] md:text-[1rem] text-[.875rem] ${
                    pathname === items.url
                      ? "text-[#002DA3] font-[500]"
                      : "text-[#4B4E56]"
                  }`}
                >
                  {items.name}
                </p>
              </Link>
            ))}
          </div>
          <div className="lg:flex hidden items-center gap-4 text-14 md:text-16 font-bold text-3E3C3F">
            {sociallogos.map((items, i) => (
              <Link to={items.url} key={i} target="_">
                <img
                  src={process.env.PUBLIC_URL + items.imgpath}
                  alt={items.name}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>

      {/* MARK: for Mobile view menubar */}

      <div className="flex lg:hidden absolute right-0 z-300 menubarbtn bg-[#002DA3] h-full w-[17%] sm:w-[11%] md:w-[8%] items-center justify-center">
        <button
          className="relative flex flex-col items-center justify-center w-12 h-12 rounded focus:outline-none"
          onClick={handleclick}
        >
          <span
            className={`block w-6 h-0.3 border-white border-[1px] opacity-75 border-black transform transition duration-300 ease-in-out ${
              menuOpen ? "rotate-45 translate-y-1" : "-translate-y-1.5"
            }`}
          ></span>
          <span
            className={`block w-6 h-0.3 border-white border-[1px] transform transition duration-300 ease-in-out ${
              menuOpen ? "opacity-0" : "opacity-75"
            }`}
          ></span>
          <span
            className={`block w-6 h-0.3 border-white border-[1px] opacity-75 transform transition duration-300 ease-in-out ${
              menuOpen ? "-rotate-45 -translate-y-1.2" : "translate-y-1.5"
            }`}
          ></span>
        </button>
      </div>
      {menuOpen ? (
        <div
          ref={mobref}
          className="flex flex-col absolute text-left gap-1 text-14 md:text-16 font-bold text-white w-full flex-wrap top-[56px] md:top-[87px] bg-[#FFFFFF] py-4"
        >
          {menuitems.map((items, i) => (
            <Link
              to={items.url}
              onClick={closemenu}
              className={`px-4 py-[18px] hover:text-[#002DA3] border-b last:border-b-0 ${
                pathname === items.name.toLowerCase().trim()
                  ? "text-[#FC7B27]"
                  : "text-paratext"
              }`}
            >
              <h1>{items.name}</h1>
            </Link>
          ))}
          {/* <Link
            to={"/ourstory"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/ourstory" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Our Story</h1>
          </Link>
          <Link
            to={"/events"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/events" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Events</h1>
          </Link>
          <Link
            to={"/resources"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/resources" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Resources</h1>
          </Link>
          <Link
            to={"/communitystories"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/communitystories" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Community Stories</h1>
          </Link>
          <Link
            to={"/news&updates"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/news&updates" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>News & Updates</h1>
          </Link>
          <Link
            to={"/successstories"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/successstories" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Success Stories</h1>
          </Link>
          <Link
            to={"/blog"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/blog" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Blog</h1>
          </Link>
          <Link
            to={"/partnerships"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/partnerships" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Partnerships</h1>
          </Link>
          <Link
            to={"/donate"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/donate" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Donate/Support Us</h1>
          </Link>
          <Link
            to={"/help&support"}
            onClick={closemenu}
            className={`px-[10px] py-[8px] hover:text-[#FC7B27] ${
              pathname === "/help&support" ? "text-[#FC7B27]" : "text-white"
            }`}
          >
            <h1>Help & Support</h1>
          </Link> */}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Header;
