import React from "react";
import Downloadnow from "../Downloadnow";

const Joinvillage = () => {
  return (
    <div className="w-full 2xl:container p-4 md:px-20">
      <div
        className="flex flex-col md:flex-row bg-joinmb md:bg-join bg-center bg-cover rounded-[32px]"
        style={{
          boxShadow:
            "0px 88px 35px rgba(92, 93, 97, 0.01), 0px 50px 30px rgba(92, 93, 97, 0.05), 0px 22px 22px rgba(92, 93, 97, 0.09), 0px 6px 12px rgba(92, 93, 97, 0.1)",
        }}
      >
        <div className="flex flex-col w-full md:w-[70%] gap-6 px-4 md:px-20 py-10 md:py-[62px] md:items-start">
          <h1 className="text-24 md:text-48 text-[#0D121F] font-[800] md:text-left">
            Join Your Village.
          </h1>
          <p className="text-14 md:text-16 text-[#4B4E56] md:w-7/12 text-center md:text-left">
            Join thousands of parents building stronger tomorrows for their
            neurodivergent children.
          </p>
        </div>
        <div className="mb-10 md:mb-0 md:w-[30%] flex items-center justify-center">
          {/* <button className="py-2 px-6 md:px-[25px] md:py-[16px] bg-[#002DA3] text-14 text-white font-[600] rounded-[32px]">
            Download Now
          </button> */}
          <Downloadnow />
        </div>
      </div>
    </div>
  );
};

export default Joinvillage;
