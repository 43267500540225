import React from "react";

const Resources = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="2xl:container w-full flex flex-col items-center py-[72px] md:px-20 p-4 gap-6 md:gap-10 min-h-[450px] md:min-h-[600px] justify-center resourcebg">
        <div className="text-24 md:text-32 lg:text-48 md:leading-[72px] gap-4 md:gap-5 flex flex-col">
          <h1 className="font-avenir font-[800] text-headingtext text-24 md:text-32 lg:text-48">
            Coming Soon{" "}
          </h1>
          <h1 className="font-avenir font-[800] text-headingtext text-24 md:text-32 lg:text-48">
            {" "}
            Your Neurodiversity Toolkit
          </h1>
        </div>
        <p className="w-full md:w-7/12 text-14 md:text-18 font-medium">
          This section will be your go-to library for navigating neurodiversity.
          From expert articles and practical guides to videos and checklists,
          we're curating resources to empower families with knowledge and
          support. Stay tuned for a treasure trove of tools to make your journey
          smoother.
        </p>
      </div>
    </div>
  );
};

export default Resources;
