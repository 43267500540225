import React from "react";
import { Link, useLocation } from "react-router-dom";

const contents = [
  {
    mini_title: "Navigating the Early Days",
    title: "“A Parent's Guide to Understanding Neurodiversity”",
    para: "Practical insights and emotional support for parents beginning their journey with a newly diagnosed neurodivergent child. Expert advice meets real parent experiences.",
    post: "Blog Post: 468words",
    date: "Feb 10",
    img: "images/blog/blgimg1.webp",
  },
  {
    mini_title: "Building Your Support Network",
    title: "“Finding and Connecting with Other Parents”",
    para: "Discover strategies for creating meaningful connections with other families, both online and in your local community.",
    post: "Blog Post: 536 words",
    date: "Mar 10, 2024",
    img: "images/blog/blgimg2.webp",
  },
  {
    mini_title: "Self-Care for Caregivers",
    title: "“Maintaining Balance While Supporting Your Child”",
    para: "Essential guidance for parents on maintaining emotional well-being while navigating the demands of caregiving.",
    post: "Blog Post: 432 words",
    date: "May 10, 2023",
    img: "images/blog/blgimg3.webp",
  },
];

const Blog2 = () => {
  return (
    <div className="2xl:container w-full flex flex-col px-6 md:px-[70px]">
      <div className="flex flex-col gap-6 md:gap-12">
        {contents.map((item, index) => (
          <>
            <Link to={`/blog/${index + 1}`}>
              <div className="grid grid-cols-[3fr_1fr] md:grid-cols-[2.3fr_1fr]">
                <div className="text-left">
                  <div className="flex flex-col gap-3 md:gap-4 mb-8 md:mb-10 w-[80%] md:w-10/12">
                    <p className="text-14 md:text-20 font-medium text-[#046353]">
                      {item.mini_title}
                    </p>
                    <p className="text-20 md:text-36 font-[700] text-headingtext">
                      {item.title}
                    </p>
                    <p className="text-16 text-[#41444B] hidden md:block">
                      {item.para}
                    </p>
                  </div>
                  <div className="hidden md:flex text-14 md:text-16 text-headingtext font-medium gap-10">
                    <p>{item.post}</p>
                    <p>{item.date}</p>
                  </div>
                </div>

                <img
                  src={`${item.img}`}
                  alt="dd"
                  className="rounded-[12px] min-w-[140px] min-h-[100px]"
                  style={{
                    boxShadow: "0px 0px 20px rgba(114, 116, 121, 0.25)",
                  }}
                />
              </div>

              <div className="block md:hidden text-14 md:text-16 text-headingtext font-medium flex justify-between">
                <p>{item.post}</p>
                <p>{item.date}</p>
              </div>
            </Link>

            {index !== contents.length - 1 && (
              <hr className="h-[1px] text-[#CED0D6]" />
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default Blog2;
