import React from "react";
import Herosection from "../components/landingpage/Herosection";
import Ourcommitment from "../components/landingpage/Ourcommitment";
import Mission from "../components/landingpage/Mission";
import Exist from "../components/landingpage/Exist";
import Contactus from "../components/landingpage/Contactus";
import Aboutus from "../components/landingpage/Aboutus";
import Joinvillage from "../components/landingpage/Joinvillage";

const Landingpage = () => {
  return (
    <div className="w-full flex flex-col items-center gap-[48px]  md:gap-[80px] lg:gap-[120px]">
      <Herosection />
      <Ourcommitment />
      <Aboutus />
      {/* <Mission /> */}
      <Exist />
      <Joinvillage />
      {/* <Contactus /> */}
    </div>
  );
};

export default Landingpage;
