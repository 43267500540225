import React, { useEffect, useRef, useState } from "react";
import SectionHeader from "../SectionHeader";

const Ourfeatures = () => {
  const outerdiv = useRef(null);
  const [width, setWidth] = useState(0);
  const [currentItem, setCurrentItem] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(1);
  const [startX, setStartX] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (outerdiv.current) {
        const containerWidth = outerdiv.current.clientWidth;
        setWidth(containerWidth);

        // Adjust the number of visible items based on screen size
        if (window.innerWidth >= 1024) {
          setItemsToShow(3); // lg screen - 3 items
        } else if (window.innerWidth >= 768) {
          setItemsToShow(2); // md screen - 2 items
        } else {
          setItemsToShow(1); // sm screen - 1 item
        }
      }
    };

    handleResize(); // Initial call
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePrevious = () => {
    setCurrentItem((prev) => (prev > 0 ? prev - 1 : data.length - itemsToShow));
  };

  const handleNext = () => {
    setCurrentItem((prev) => (prev < data.length - itemsToShow ? prev + 1 : 0));
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!startX) return;

    const currentX = e.touches[0].clientX;
    const diffX = startX - currentX;

    if (diffX > 50) {
      handleNext();
      setStartX(null);
    } else if (diffX < -50) {
      handlePrevious();
      setStartX(null);
    }
  };

  const data = [
    {
      name: "Find Connections",
      desc: "Connect with support groups and families based on shared experiences, interests, and location using our easy-to-navigate filters. Find the people who truly understand your journey.",
    },
    {
      name: "Verified Resources",
      desc: "Access trusted neurodivergent family therapists and resources in your area.",
    },
    {
      name: "Local Events",
      desc: "Discover neurodiversity events and activities that bring online connections into real life.",
    },
    {
      name: "Safe Community",
      desc: "Share experiences in a moderated, supportive environment designed for the neurodivergent community.",
    },
  ];

  return (
    <div className="2xl:container flex justify-center items-center md:px-[70px] px-6 py-[48px] md:py-[120px] bg-featuresmobilebg md:bg-featuresbg w-full bg-cover">
      <div className="flex flex-col items-center gap-8 md:gap-[58px] overflow-hidden">
        <SectionHeader
          title={"OUR FEATURES"}
          description={"Trusted Connections & Local Growth"}
          textalign={"center"}
          justify={"center"}
        />
        <div
          ref={outerdiv}
          className="relative overflow-hidden w-full"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            className="relative flex w-full gap-[30px] transition-transform duration-300"
            style={{
              transform: `translateX(-${
                currentItem * (width / itemsToShow + 10) // Adjust for gap between items
              }px)`,
            }}
          >
            {data.map((item, i) => (
              <div
                key={i}
                className="flex flex-col min-w-[calc(100%_/_3-20px)] md:min-w-[calc(100%_/_2-20px)] sm:min-w-[100%] text-left gap-3 md:gap-6 px-6 py-8 bg-white rounded-[16px] shadow-md"
                style={{
                  minWidth: `${width / itemsToShow - 20}px`, // Adjust for gap
                }}
              >
                <h1 className="font-avenir text-16 md:text-20 text-headingtext font-[600]">
                  {item.name}
                </h1>
                <p className="text-14 md:text-16 text-paratext">{item.desc}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex gap-5 justify-center">
          <button
            onClick={handlePrevious}
            className="h-8 w-8 md:h-[45px] md:w-[45px] rounded-full border-[1px] border-[#1D43B6] flex justify-center items-center testibtn"
          >
            <div className="rotate-180 text-highlight arrowicon mb-2" />
          </button>
          <button
            onClick={handleNext}
            className="h-8 w-8 md:h-[45px] md:w-[45px] rounded-full border-[1px] border-[#1D43B6] flex justify-center items-center testibtn1"
          >
            <div className="text-highlight arrowicon mt-1" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Ourfeatures;
