import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Landingpage from "./page/Landingpage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Ourstory from "./page/Ourstory";
import Eventspage from "./page/Eventspage";
import Resources from "./page/Resources";
import CommunityStories from "./page/CommunityStories";
import News from "./page/News";
import Successstories from "./page/Successstories";
import Blog from "./page/Blog";
import Contactus from "./page/Contactas";
import Becomeanally from "./page/Partnerships";
import Help from "./page/Help";
import Donate from "./page/Donate";
import { useEffect, useState } from "react";
import Headermenu from "./components/Headermenu";
import Scrolltotop from "./components/Scrolltotop";
import Privacy from "./page/Privacy";
import Terms from "./page/Terms";
import Ourapp from "./page/Ourapp";
import IndividualBlog from "./page/IndividualBlog";
import { Toaster } from "react-hot-toast";
// import Modal from "./components/Modal";

function App() {
  let url = useLocation();
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  console.log(mobileMenuOpen);

  url = url.pathname.replace("/", "");

  useEffect(() => {
    if (url === "index.html") {
      navigate("/");
    }
  }, [url]);

  const toggleMenu = () => setMobileMenuOpen(false);

  return (
    <div className="App relative">
      <Header setMobileMenuOpen={setMobileMenuOpen} />
      {/* <Headermenu /> */}
      <Scrolltotop />
      <Toaster />
      {/* Overlay to dim the background */}
      {mobileMenuOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-30"
          onClick={toggleMenu}
        ></div>
      )}
      <div className="w-full 2xl:container">
        <Routes>
          <Route path={"/"} element={<Landingpage />}></Route>
          <Route path={"/ourstory"} element={<Ourstory />} />
          <Route path={"/events"} element={<Eventspage />} />
          <Route path={"/resources"} element={<Resources />} />
          <Route path={"/communitystories"} element={<CommunityStories />} />
          <Route path={"/news&updates"} element={<News />} />
          <Route path={"/successstories"} element={<Successstories />} />
          <Route path={"/blog"} element={<Blog />}>
            <Route path={":eachblog"} element={<IndividualBlog />} />
          </Route>
          <Route path={"/contactus"} element={<Contactus />} />
          <Route path={"/becomeanally"} element={<Becomeanally />} />
          <Route path={"/donate"} element={<Donate />} />
          <Route path={"/help&support"} element={<Help />} />
          <Route path={"/privacy"} element={<Privacy />} />
          <Route path={"/terms_and_conditions"} element={<Terms />} />
          <Route path={"/ourapp"} element={<Ourapp />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;
