import React from "react";

const Findourvillage = () => {
  return (
    <div className="2xl:container md:px-[70px]">
      <div className="flex flex-col md:flex-row">
        <div className="md:w-[40%] flex flex-col p-6 md:p-10 text-left bg-[#FBF6F2] gap-4 villageborder">
          <h1 className="text-avenir text-headingtext text-18 md:text-36 font-[600]">
            Finding Our Village
          </h1>
          <p className="text-14 md:text-16 text-paratext mt-1 md:mt-4">
            Parenting is a journey filled with unexpected turns. When my son was
            diagnosed with autism, I found myself searching for guidance and
            connection. As a parent of a neurodivergent child, while resources
            for children were available, there was a crucial gap—support for
            parents and caregivers navigating this unique path.
          </p>
          <p className="text-14 md:text-16 text-paratext">
            Those early days taught me the profound strength that comes from
            shared experiences. Each connection with another parent brought
            invaluable insights and a sense of belonging. Yet, finding these
            connections remained challenging for many families in the
            neurodivergent community.
          </p>
        </div>
        <div className="flex flex-col md:w-[60%]">
          <div className="h-[60%] overflow-hidden ">
            <img
              className="h-full w-full object-fill hidden md:block"
              src={
                process.env.PUBLIC_URL +
                "/images/ourstoryhero/findourvillageimg.webp"
              }
              alt="finding our village illustration"
            />
            <img
              className="h-full w-full object-fill block md:hidden"
              src={
                process.env.PUBLIC_URL + "/images/ourstoryhero/fovmobile.webp"
              }
              alt="finding our village illustration"
            />
          </div>
          <div className="h-[30%] text-left md:text-right">
            <p className="p-6 md:pl-10 md:py-6 text-14 md:text-16 text-left text-[#41444B]">
              This realization sparked Social Spectrum a space I wished for
              during my own journey. A platform where every parent feels
              understood, supported, and empowered.
            </p>
            <p className="text-right pr-6 md:p-0 text-16 md:text-16 font-[800] mb-1 md:mb-3">
              _Archana Akhaury
            </p>
            <p className="text-right pr-6 md:pr-0 text-14 md:text-16 text-paratext">
              Founder, Social Spectrum
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Findourvillage;
