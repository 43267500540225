import React from "react";
import { Link } from "react-router-dom";

const items = [
  {
    color: "#41A89B",
    text: "Build lasting connections with families who need your expertise",
  },
  {
    color: "#6785FF",
    text: "Gain visibility in a trusted community",
  },
  {
    color: "#EE5A30",
    text: "Access tools and forums to expand your impact.",
  },
];

const Becomeanally1 = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="2xl:container w-full flex flex-col items-center min-h-[450px] md:min-h-[500px] partnershipbg relative">
        <div className="font-avenir font-[800] text-24 md:text-32 text-headingtext lg:text-48 md:leading-[72px] flex flex-col mb-4 md:mb-6 pt-16 md:pt-[96px]">
          <p>Join Our Village</p>
        </div>
        <p className="w-10/12 lg:w-6/12 text-14 md:text-18 font-medium text-paratext mb-6 md:mb-12">
          Help build stronger support systems for neurodivergent families. Share
          your expertise with a community that needs your guidance.
        </p>

        <Link
          to={"/contactus"}
          className="px-6 py-4 bg-[#002DA3] text-[#FFFFFF] text-14 md:text-16 rounded-[32px]"
        >
          Join Now
        </Link>

        <div
          className="p-6 md:p-10 w-[89%] md:w-[75%] rounded-[24px] border-[1px] border-[#CED0D6] relative bottom-[-150px] md:bottom-[-100px] z-1 bg-white"
          style={{
            boxShadow:
              "0px 74px 30px rgba(114, 116, 121, 0.01), 0px 42px 25px rgba(114, 116, 121, 0.04), 0px 19px 19px rgba(114, 116, 121, 0.07), 0px 5px 10px rgba(114, 116, 121, 0.08)",
          }}
        >
          <div>
            <p className="text-18 md:text-24 font-medium text-headingtext">
              What's in it for you?
            </p>
          </div>
          <div className="flex flex-col lg:flex-row gap-4 md:gap-6 mx-auto mt-8">
            {items.map((item, index) => (
              <div
                className={`flex flex-row items-center text-left gap-3 bg-[${item.color}] bg-opacity-10 border-[1px] border-[${item.color}] rounded-[16px] py-4 md:py-6 px-4 md:px-5 max-w-full lg:max-w-[325px]`}
              >
                <div className="min-w-[24px]">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 12L11 14L15 10M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z"
                      stroke={item.color}
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <p className="text-14 md:text-16 text-headingtext font-medium">
                  {item.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Becomeanally1;
