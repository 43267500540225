import React from "react";
import Becomeanally1 from "../components/Becomeanally/Becomeanally1";
import Becomeanally2 from "../components/Becomeanally/Becomeanally2";
import Joinvillage from "../components/landingpage/Joinvillage";

const Becomeanally = () => {
  return (
    <div className="w-full flex flex-col items-center gap-[56px] md:gap-[120px]">
      <Becomeanally1 />
      <Becomeanally2 />
      <Joinvillage />
    </div>
  );
};

export default Becomeanally;
