import React from "react";


const Blog1 = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="2xl:container w-full flex flex-col items-center blobbg relative">
        <div className="font-avenir font-[900] text-24 md:text-32 text-headingtext lg:text-48 md:leading-[72px] flex flex-col py-16 md:py-[96px]">
          <p>Our Blog</p>
        </div>
      </div>
    </div>
  );
};

export default Blog1;