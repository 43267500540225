import React from "react";

const Herosection = () => {
  return (
    <div
      className="2xl:container w-full h-fit min-h-[400px] lg:h-[700px] ourstorybg"
      style={{ backgroundSize: "100% 100%" }}
    >
      <div className="p-4 py-10 text-center md:p-10 lg:px-20 lg:py-[96px] w-full items-center flex flex-col gap-4 ">
        {/* <h1 className="font-seagoe text-060D24 text-24 md:text-32 lg:text-48">
          Our Story
        </h1> */}
        <h1 className="font-avenir font-[800] text-headingtext text-24 md:text-32 lg:text-48 mb-6">
          Every Journey Has a Beginning
        </h1>
        <p className="w-11/12 lg:w-6/12 text-paratext text-14 font-medium md:text-18">
          When one mother's search for support became a mission to connect
          thousands of families navigating neurodiversity.
        </p>
      </div>
    </div>
  );
};

export default Herosection;
