import React from "react";

const Mobilesection = () => {
  return (
    <div className="2xl:container flex justify-center items-center md:px-[70px] px-6">
      <div className="flex md:flex-row justify-center items-start">
        <div className="h-full w-full lg:w-[80%] flex flex-col-reverse lg:flex lg:flex-row items-center gap-8 lg:justify-start relative ml-auto">
          <img
            className="h-auto md:w-[50%] hidden lg:block"
            src={process.env.PUBLIC_URL + "/images/ourapp/mobileframe.webp"}
            alt="mobile application view"
          />
          <img
            className="h-auto w-[50%] block lg:hidden mt-3"
            src={
              process.env.PUBLIC_URL + "/images/ourapp/mobileframemobile.webp"
            }
            alt="mobile application view"
          />

          <div className="flex flex-col lg:w-[40%] lg:max-w-[520px] lg:absolute gap-3 md:gap-6 lg:text-left right-[20%] top-[10%]">
            <h1 className="text-headingtext md:text-36 text-18 font-[600]">
              Designed for Real Needs
            </h1>
            <p className="text-14 md:text-16 text-paratext">
              Social Spectrum combines{" "}
              <strong>parent support, professional resources</strong>, and{" "}
              <strong>local connections</strong> in one intuitive platform.
              We're making it easier for families navigating neurodivergence to
              find their community and access neurodiversity family support.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mobilesection;
