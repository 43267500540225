import React from "react";
import SectionHeader from "../SectionHeader";

const Exist = () => {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="2xl:container p-4 py-[35px] md:px-10 w-full flex flex-col items-center md:gap-10">
        <SectionHeader
          title={"WHY WE EXIST?"}
          description={"Together, We Create a Safe and Supportive Space"}
          para={
            "Social Spectrum bridges the gap for neurodivergence families seeking connection and support. We provide a dedicated space to share experiences, find empathy, and access valuable resources."
          }
          width={"60%"}
          justify={"center"}
        />
        <div className=" grid grid-cols-1 md:grid-cols-3 w-full justify-around mt-10 md:mt-[72px] p-4 gap-8 md:gap-5">
          <div className="flex flex-col gap-10">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/exist/exist1.webp"}
                alt="existimg"
              />
            </div>
          </div>
          <div className="flex flex-col gap-10">
            <div className="">
              <img
                src={process.env.PUBLIC_URL + "/images/exist/exist2.webp"}
                alt="existimg"
              />
            </div>
          </div>
          <div className="flex flex-col gap-10 m-auto">
            <div className="m-auto">
              <img
                src={process.env.PUBLIC_URL + "/images/exist/exist3.webp"}
                alt="existimg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exist;
