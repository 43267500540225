import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { sentDetails } from "../../api/api";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const Contactus1 = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    service: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({ ...prev, phone: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    toast.success("Your details is being submitted");
    // Create a promise to handle the response manually

    try {
      const response = await sentDetails(formData);
      // console.log(response);

      if (response.data) {
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
          service: "",
        });
        toast.success(response.data.message || "Message sent successfully!");
      } else if (response.error) {
        toast(
          response.error.message + ", Message could not be sent" ||
            "Failed to send message"
        );
      }
    } catch (err) {
      toast("An unexpected error occurred");
    }

    // Use toast.promise to handle loading, success, and error states
    // toast.promise(submissionPromise, {
    //   loading: "Sending message...",
    //   success: (message) => message,
    //   error: (message) => message,
    // });
  };

  // console.log(formData);

  const sociallogos = [
    {
      name: "facebook",
      imgpath: "/images/header/Facebook.svg",
      url: "https://www.facebook.com/share/17eZ7J3oY9/?mibextid=qi2Omg",
    },
    {
      name: "Instagram",
      imgpath: "/images/header/Instagram.svg",
      url: "https://www.instagram.com/socialspectrum2024?igsh=MW1jaWhzZnNlcHVzbw==",
    },
    {
      name: "LinkedIn",
      imgpath: "/images/header/LinkedIn.svg",
      url: "https://www.linkedin.com/company/socialspectrum/",
    },
    {
      name: "X",
      imgpath: "/images/header/X.svg",
      url: "https://x.com/SocialSpectrum4?s=08",
    },
  ];

  return (
    <div className="w-full flex  items-center justify-center">
      <div className="2xl:container w-full flex flex-col lg:flex-row gap-[56px] md:gap-16">
        <div className="text-left border-[1px] border-[#CED0D6] lg:w-5/12">
          <img
            src="images/contactus/herobannerimg.webp"
            alt="Contactus illustration downloaded from freepik"
            className="w-full"
          />
          <div className="pl-6 md:pl-[70px] pr-6 md:pr-12 py-6 md:py-12">
            <div className="flex gap-4 md:gap-6 mb-10">
              <div>
                <img
                  src="images/contactus/mail_i.png"
                  alt=""
                  className="w-8 md:w-full"
                />
              </div>
              <div className="">
                <p className="text-14 md:text-20 font-bold text-headingtext mb-5 font-avenir">
                  Email
                </p>
                <p className="text-14 md:text-16 text-[#41444B] mb-3">
                  <a href="mailto:admin@socialspectrum.org">
                    admin@socialspectrum.org 
                  </a>
                </p>
                <p className="text-14 md:text-16 text-[#41444B]">
                  <a
                    href="mailto:archana.akhaury@socialspectrum.org"
                    className=""
                  >
                    archana.akhaury@socialspectrum.org
                  </a>
                </p>
              </div>
            </div>
            <div className="flex gap-6">
              <div className="min-w-8">
                <img
                  src="images/contactus/location_i.png"
                  alt=""
                  className="w-8 md:w-full"
                />
              </div>
              <div>
                <p className="text-16 md:text-20 font-bold text-headingtext mb-5 font-avenir">
                  Location
                </p>
                <div className="flex flex-col md:flex-row gap-5 md:gap-[90px] md:gap-[139px]">
                  <p className="text-14 md:text-16 text-[#41444B]">
                    Vic 3029, Australia.
                  </p>
                  <div className="flex gap-4">
                    {sociallogos.map((items, i) => (
                      <Link to={items.url} key={i} target="_">
                        <img
                          src={process.env.PUBLIC_URL + items.imgpath}
                          alt={items.name}
                        />
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-left mt-[0px] lg:mt-[60px] px-6 md:px-14 lg:px-[0px]">
          <p className="text-14 md:text-16 font-bold text-[#046353] mb-3 md:mb-5">
            CONTACT US
          </p>
          <p className="text-20 md:text-36 font-bold font-avenir">
            Let's Chat! Get in Touch with Us.
          </p>
          <form
            onSubmit={handleSubmit}
            className="mt-8 md:mt-12 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-5 w-full max-w-4xl mx-auto"
          >
            {/* Parent / Guardian Name */}
            <div className="flex flex-col gap-3 md:gap-4">
              <label className="text-14 md:text-16 text-headingtext font-medium">
                Parent / Guardian Name*
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter parent / guardian name"
                className="text-12 md:text-16 border-[1px] border-[#CED0D6] rounded-[16px] px-3 py-3 md:py-4 focus:outline-none focus:border-gray-500 max-w-full md:max-w-[300px]"
                required
              />
            </div>

            {/* Child's Age */}
            <div className="flex flex-col gap-3 md:gap-4">
              <label className="text-14 md:text-16 text-headingtext font-medium">
                Child’s Age*
              </label>
              <input
                type="number"
                name="service"
                value={formData.service}
                onChange={handleChange}
                placeholder="Enter your child’s age"
                className="text-12 md:text-16 border-[1px] border-[#CED0D6] rounded-[16px] px-3 py-3 md:py-4 focus:outline-none focus:border-gray-500  max-w-full md:max-w-[300px]"
                required
              />
            </div>

            {/* Email Address */}
            <div className="flex flex-col gap-3 md:gap-4">
              <label className="text-14 md:text-16 text-headingtext font-medium">
                Email Address*
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email ID"
                className="text-14 md:text-16 border-[1px] border-[#CED0D6] rounded-[16px] px-3 py-3 md:py-4 focus:outline-none focus:border-gray-500  max-w-full md:max-w-[300px]"
                required
              />
            </div>

            {/* Phone Number */}
            <div className="flex flex-col gap-3 md:gap-4">
              <label className="text-14 md:text-16 text-headingtext font-medium">
                Phone Number*
              </label>
              <div className="w-full relative flex border-[1px] border-[#CED0D6] rounded-[16px] focus-within:border-gray-500  max-w-full md:max-w-[305px]">
                {/* <select
                  style={{ color: "gray" }}
                  className="bg-transparent text-12 md:text-16 pl-3 py-4 rounded-l-[16px] focus:outline-none max-w-[60px]"
                >
                  <option value="US">US</option>
                  <option value="+1">+1</option>
                  <option value="+44">+44</option>
                  <option value="+61">+61</option>
                </select> */}

                <PhoneInput
                  name="phone"
                  defaultCountry="AU"
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  placeholder="Enter your phone number"
                  className="text-14 md:text-16 px-3 py-3 md:py-4 focus:outline-none focus:border-gray-500 outline-none ml-2 border-[#CED0D6]"
                  required
                />
              </div>
            </div>

            {/* We’re Listening */}
            <div className="flex flex-col md:col-span-2 gap-3 md:gap-4">
              <label className="text-14 md:text-16 text-headingtext font-medium">
                We’re Listening
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="4"
                placeholder="Share your thoughts..."
                className="text-14 md:text-16 border-[1px] border-[#CED0D6] rounded-[16px] px-3 py-3 md:py-4 focus:outline-none focus:border-gray-500 max-w-[650px]"
              />
            </div>

            {/* Submit Button */}
            <div className="md:col-span-2 flex flex-col md:flex-row gap-8 md:gap-0 justify-between">
              <div className="flex gap-4 w-full md:w-[53%]">
                <input
                  type="checkbox"
                  id="terms"
                  required
                  className="w-4 md:w-6 h-4 md:h-6 mt-[4px] accent-[#0f1cbe] checked:border-[#002DA3]"
                />
                <label
                  htmlFor="terms"
                  className="text-14 md:text-16 text-[#41444B]"
                >
                  By checking this box, you agree to our <b>terms</b> and{" "}
                  <b>privacy policy.</b>
                </label>
              </div>
              <button
                type="submit"
                className="bg-[#002DA3] text-white font-bold px-6 py-4 rounded-[32px] hover:bg-blue-700 transition duration-300"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contactus1;
