import React from "react";

const Downloadnow = () => {
  const handleDownload = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent) || /Windows/i.test(userAgent)) {
      // Redirect to Play Store (Android + Windows)
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.social_spectrum.mobile";
    } else if (/iPad|iPhone|iPod|ios/.test(userAgent) && !window.MSStream) {
      // Redirect to App Store (iOS)
      window.location.href =
        "https://apps.apple.com/us/app/social-spectrum/id6473112455";
    } else {
      // Optional: Fallback link or message
      alert("Unsupported platform");
    }
  };

  return (
    <div className="">
      <button
        onClick={handleDownload}
        className="m-2 py-3 px-6 md:px-[25px] md:py-[16px] bg-[#002DA3] text-14 text-white font-[600] rounded-[32px] border-[white] border-[1px]"
      >
        Download Now
      </button>
    </div>
  );
};

export default Downloadnow;
