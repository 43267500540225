import React, { useEffect, useState } from "react";
import Blog1 from "../components/Blog/Blog1";
import Blog2 from "../components/Blog/Blog2";
import Joinvillage from "../components/landingpage/Joinvillage";
import { Outlet, useParams } from "react-router-dom";
import data from "../JSON/Blog.json";

const Blog = () => {
  const { eachblog } = useParams();
  const [currentdata, setCurrentData] = useState("");

  useEffect(() => {
    setCurrentData(data[eachblog - 1]);
  }, [eachblog]);

  return (
    <div className="w-full flex flex-col items-center gap-[56px] md:gap-[120px]">
      {/* <div className="2xl:container w-full flex flex-col items-center py-[72px] md:px-20 p-4 gap-10 min-h-[600px] justify-center blogbg">
        <div className="font-seagoe text-24 md:text-32 lg:text-48 md:leading-[72px] gap-5 flex flex-col">
          <h1>Coming Soon </h1>
          <h1>Insights and Updates</h1>
        </div>
        <p className="w-full md:w-7/12 md:text-18 font-medium">
          Our blog will bring you fresh perspectives on parenting,
          neurodiversity, and inclusion. Featuring thought-provoking articles,
          tips, and reflections from experts and caregivers, this space is
          designed to keep you informed and inspired.
        </p>
      </div> */}
      {eachblog === undefined ? (
        <>
          {" "}
          <Blog1 />
          <Blog2 />
          <Joinvillage />{" "}
        </>
      ) : (
        currentdata && <Outlet context={{ currentdata }} />
      )}
    </div>
  );
};

export default Blog;
