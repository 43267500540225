import React from "react";

const SectionHeader = ({
  title,
  description,
  para,
  textalign,
  color,
  justify,
  width,
}) => {
  return (
    <div className={`flex justify-center w-full items-center`}>
      <div
        className={`md:w-[${width}] flex flex-col items-${justify} gap-3 md:gap-6`}
      >
        <div className="text-12 md:text-14 md:text-16 text-[#046353] font-[600]">
          {title && <p>{title}</p>}
        </div>
        <div className="text-18 md:text-36 text-headingtext font-[800] font-avenir">
          {description && <h1>{description}</h1>}
        </div>
        <div
          className={`text-${textalign} w-full md:w-8/12 text-paratext text-14 md:text-16`}
        >
          {para && <p>{para}</p>}
        </div>
      </div>
    </div>
  );
};

export default SectionHeader;
