import React from "react";
import Herosection from "../components/Ourapp/Herosection";
import Mobilesection from "../components/Ourapp/Mobilesection";
import Ourfeatures from "../components/Ourapp/Ourfeatures";
import Howitworks from "../components/Ourapp/Howitworks";
import Joinvillage from "../components/landingpage/Joinvillage";

const Ourapp = () => {
  return (
    <div className="w-full flex flex-col items-center gap-14 md:gap-[120px]">
      <Herosection />
      <Mobilesection />
      <Ourfeatures />
      <Howitworks />
      <Joinvillage />
    </div>
  );
};

export default Ourapp;
