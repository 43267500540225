import React from "react";


const Becomeanally2 = () => {
  return (
    <div className="mt-[150px] md:mt-[100px]">
      <img src="images/becomeanally/mobileimg.webp" alt="mobile" className="w-full hidden md:block" />
      <img src="images/becomeanally/mobileimg_phn.webp" alt="mobile" className="w-full block md:hidden" />
    </div>
  );
};

export default Becomeanally2;