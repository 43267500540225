import React from "react";
import Downloadnow from "../Downloadnow";

const Herosection = () => {
  return (
    <div className="2xl:container p-4 md:p-20 w-full flex flex-col items-center herobg relative bg-no-repeat bg-center gap-10 md:gap-[75px] h-[450px] sm:h-[500px] md:h-[600px] lg:h-[750px]">
      <div className="w-full py-1 md:py-0 md:w-7/12 flex flex-col gap-3 md:gap-6 items-center text-white z-10 mt-auto">
        <div className="font-avenir font-[800] text-24 md:text-32 lg:text-48">
          <h1>Navigating Neurodiversity Together.</h1>
        </div>
        <div className="text-14 sm:text-16 md:text-18 md:w-11/12">
          <p>
            Where neurodivergence finds its village. Join a compassionate
            community, access expert resources, and build meaningful
            connections—all in one app.
          </p>
        </div>
        <div className="mt-3">
          <Downloadnow />
        </div>
      </div>
      {/* <div className="w-full md:w-9/12 bg-white z-10 flex flex-col md:flex-row downloadnow gap-5 md:gap-0">
        <div className="w-full md:w-7/12 p-4 md:py-[50px] text-left md:pl-[64px]">
          <h1 className="text-24 md:text-32 lg:text-40 text-3486D9 font-bold">
            Download App Now!
          </h1>
          <p className="text-18 text-3E3C3F mt-6">
            Social Spectrum offers a supportive space for families to connect,
            share, and grow together. Join us to celebrate victories, exchange
            insights, and build a more inclusive community.
          </p>
          <div className="mt-10 flex gap-5">
            <button>
              <img
                src={process.env.PUBLIC_URL + "/images/herosection/android.svg"}
                alt="downloadappinandroid"
              ></img>
            </button>
            <button>
              <img
                src={process.env.PUBLIC_URL + "/images/herosection/mac.svg"}
                alt="downloadappinios"
              ></img>
            </button>
          </div>
        </div>
        <div className="w-full md:w-5/12 flex justify-center items-end p-4 md:p-0">
          <div className="">
            <img
              src={
                process.env.PUBLIC_URL + "/images/herosection/downloadnow.svg"
              }
              alt="illustration"
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Herosection;
