import React from "react";
import SectionHeader from "../SectionHeader";
import { Link } from "react-router-dom";

const Aboutus = () => {
  return (
    <div className="w-full md:flex justify-center items-center md:h-[700px] aboutbg relative">
      <div className="block md:hidden aboutbg h-[330px]"></div>
      <div className="p-9 w-full md:w-[30%] bg-white md:absolute md:right-[8%] flex flex-col items-start gap-10">
        <div className="flex flex-col items-start gap-3 md:gap-6">
          <p className="text-14 md:text-16 text-[#046353] font-[600]">
            About us
          </p>
          <h1 className="font-avenir text-16 md:text-20 font-[600] text-headingtext text-left">
            Built for Caregivers, by a Caregiver
          </h1>
          <p className="text-14 md:text-14 text-paratext text-left">
            Born from lived experience, Social Spectrum is a community-driven
            platform designed to empower families navigating neurodivergence.
          </p>
        </div>
        <div className="">
          <Link
            to={"/ourstory"}
            className="py-3 px-6 md:px-[25px] md:py-[16px] bg-[#002DA3] text-14 text-white font-[600] rounded-[32px]"
          >
            Read Our Story
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
